<script>
import gql from 'graphql-tag'
import cloneDeep from 'lodash/cloneDeep';
import toInt from 'lodash/toInteger';
import { mapState, mapGetters } from 'vuex';

export default {
  components: {
    apexcharts: () => import('vue-apexcharts'),
  },

  data() {
    return {
      loading: false,
      maxY: 6,
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350,
        },

        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
          },
        },

        dataLabels: {
          enabled: false,
        },

        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },

        title: {
          text: 'Oferta de Pré Pastejo',
          align: 'center',
        },

        subtitle: {
          text: 'Quilogramas de Matéria Seca',
          align: 'center',
        },

        yaxis: {
          min: 0,
          max: () => this.maxY,
          labels: {
            formatter: function (val) {
              if (typeof val === 'number')
                return val.toLocaleString(undefined, { useGrouping: true, maximumFractionDigits: 0 });
              return val;
            },
          },
        },

        xaxis: {
          categories: [],
        },

        fill: {
          opacity: 1,
        },
      },

      series: [],
    };
  },

  computed: {
    ...mapState('dashboard', ['filter', 'paddocks']),
    ...mapGetters('auth', ['authStatus', 'user']),
  },

  watch: {
    filter() {
      this.fetch();
    },
  },

  methods: {

    async fetch() {
        this.loading = true
        let form = {}
        form.farm_id = this.filter.farmId
        form.module_id = this.filter.moduleId
        form.calc_type = 'NUMBER_OF_HEADS_THAT_FIT_IN_MODULE'
        form.genetic_pattern = 'ZEBUINO'
        form.category_animal = 'MACHO_12'
        form.category_complementary = null
        form.day_occupation = 1
        form.number_of_heads = 1
        form.average_batch_weight = 1
        form.expected_performance = 1
        form.supplement_mineral_consumption = null
        form.supplement_mineral_pb = null
        form.supplement_mineral_ndt = null
        form.supplement_feed_consumption = null
        form.supplement_feed_pb = null
        form.supplement_feed_ndt = null
        form.supplement_feed_fdn = null
        form.supplement_vol_consumption = null
        form.supplement_vol_ms = null
        form.supplement_vol_pb = null
        form.supplement_vol_em = null
        form.supplement_vol_fdn = null
        form.calculator = true
        const arrayStart = this.filter.startAt.split('-');
        const arrayEnd = this.filter.endAt.split('-');
        const start = toInt(arrayStart[1]);
        const end = toInt(arrayEnd[1]);
        const yearStart = toInt(arrayStart[0])
        const yearEnd = toInt(arrayEnd[0])
        let months = ['Jan', 'Fev', 'Mar', 'Abr', 'Maio', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
        let years = yearEnd - yearStart
        if (years === 0) {
          this.chartOptions.xaxis.categories = months.slice(start - 1, 12 - start);
        } else {
          let arrayMonths = months.slice(start - 1, 12);
          for (let i = 1; i < years - 1; i++) {
            arrayMonths = [...arrayMonths,...months.slice(0, 12)]
          }
          arrayMonths = [...arrayMonths,...months.slice(0, end-1)]
          this.chartOptions.xaxis.categories = arrayMonths
        }
        const series = []
        years = years + 1
        let startFor = start
        let endFor = 13
        for (let index = 0; index < years; index++){
          if ((index+1) === years) {
            endFor = end
          }
        
        for (let i = startFor; i < endFor; i++) {
          try {
            form.reference_month = arrayStart[0] + '-' + i.toString()
            const res = await this.$apollo.query({
              query: gql`
                query calc($data: CalcInput!) {
                  calc(
                    data: $data
                  ) {
                    result
                  }
                }
              `,
              variables: {
                data: form
              },
              fetchPolicy: 'no-cache',
            });
            const configModuleDataAndAdjustedOffer = {
              moduleArea: { label: 'Área do módulo (ha)', position: 1 },
              qtdPaddocks: { label: 'Quantidade de piquetes', position: 2 },
              availableDryMatter: { label: 'Matéria seca disponível (kg/piquete)', position: 3 },
              crudeProtein: { label: 'Proteína bruta', position: 4 },
              fiberInNeutralDetergent: { label: 'Fibra em detergente neutro', position: 5 },
              metabolizableEnergy: { label: 'Energia Metabolizável (Mcal/kg)', position: 6 },
            }
            const moduleDataAndAdjustedOffer = Object.entries(res.data.calc.result.moduleDataAndAdjustedOffer)
            .map(([name, value]) => {
              return {
                name,
                value,
                ...configModuleDataAndAdjustedOffer[name]
              }
            })
            .filter(item => item.label)
            .sort((a, b) => a.position > b.position ? 1 : -1);
            const pb = moduleDataAndAdjustedOffer[3].value * 100
            const fdn = moduleDataAndAdjustedOffer[4].value * 100
            const em = moduleDataAndAdjustedOffer[5].value
            series.push({
                  
                  ms_adjusted: moduleDataAndAdjustedOffer[2].value,
                  ms_adjusted_area: moduleDataAndAdjustedOffer[2].value * moduleDataAndAdjustedOffer[1].value / moduleDataAndAdjustedOffer[0].value,
                  em: em? parseFloat(em.toFixed(2)).toLocaleString() : 0,
                  fdn: fdn? parseFloat(fdn.toFixed(1)).toLocaleString() + '%' : 0,
                  pb: pb? parseFloat(pb.toFixed(2)).toLocaleString() + '%' : 0,
                });
          } catch(e) {
            series.push({
              ms_adjusted: 0,
              ms_adjusted_area: 0,
              em: 0,
              fdn: 0,
              pb: 0,
            });
          }
        }
        arrayStart[0] = toInt(arrayStart[0]) + 1
        arrayStart[0] = arrayStart[0].toString()
        startFor = 1
      }
        this.mountChart(series);
    },

    mountChart(val) {
      const chartOptions = cloneDeep(this.chartOptions);
      let ms_adjusted = [];
      let ms_adjusted_area = [];
      for (let index = 0; index < val.length; index++) {
        let ms = {
          x: this.chartOptions.xaxis.categories[index],
          y: val[index].ms_adjusted? val[index].ms_adjusted : 0,
          goals: [
            {
              name: 'MS',
              value: val[index].ms_adjusted? parseFloat(val[index].ms_adjusted.toFixed(0)).toLocaleString(): 0,
              strokeColor: 'transparent'
            },
            {
              name: 'EM (Mcal/kg)',
              value: val[index].em,
              strokeColor: 'transparent'
            },
            {
              name: 'PB',
              value: val[index].pb,
              strokeColor: 'transparent'
            },
            {
              name: 'FDN',
              value: val[index].fdn,
              strokeColor: 'transparent'
            }
          ]
        }
        let msArea = {
          x: this.chartOptions.xaxis.categories[index],
          y: val[index].ms_adjusted_area? val[index].ms_adjusted_area : 0,
          goals: [
            {
              name: 'MS/ha',
              value: val[index].ms_adjusted_area? val[index].ms_adjusted_area.toFixed(0).toLocaleString() : 0,
              strokeColor: 'transparent'
            }
          ]
        }
        ms_adjusted.push(ms);
        ms_adjusted_area.push(msArea);
      }
      const series = [
        {
          name: 'Por Piquete',
          data: ms_adjusted,
          color: '#0000FF',
        },
        {
          name: 'Por Hectare',
          data: ms_adjusted_area,
          color: '#FFA500',
        },
      ];
      chartOptions.colors = series.map((item) => item.color);
      this.chartOptions = chartOptions;
      this.series = series;
      this.maxY = series[0].data.reduce((max, item) => (max > item.y ? max : item.y), 0) * 1.05;
      this.maxY = Math.ceil(this.maxY/100)*100
      this.loading = false
    },

    legendClickHandler(chartContext, seriesIndex) {
      let paddockId = this.series[seriesIndex].id;
      if (this.filter.paddockIds && this.filter.paddockIds.length === 1) {
        this.filter.paddockIds = [];
      } else {
        this.filter.paddockIds = [paddockId];
      }
      this.$store.dispatch('dashboard/changeFilter', this.filter);
    },
  },
};
</script>

<template>
  <v-card :loading="loading" class="chatOne">
    <v-card-text>
      <v-row>
        <v-col class="overflow-x-auto">
          <apexcharts v-show="!loading" height="500" class="overflow-chart" type="bar" :options="chartOptions" :series="series" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style lang="scss">
.apexchartschartxone {
  .apexcharts-tooltip-text-goals-label {
    .apexcharts-tooltip-marker {
      display: none;
    }
  }
}
.overflow-chart {
  min-width: 1000px;
}

.apexcharts-canvas { 
  background-image: url("../../../../../functions/images/mark-small-opacity20.png") !important;
  background-position: center;
  background-repeat:no-repeat;
}
</style>
